import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import arrowImg from "../assets/img/arrow.png"
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedVehicles } from "../store/actions";

const VehiclesDropdown = forwardRef(({ vehicles, onVehicleSelection, homePageVehicles, selectedFeatures, getUpdatedVehicles, showMenu, setShowMenu, selectedInsightId,
    isFeatureDropdownSelected, setVehiclesDropdownSelected, selectedVehicles, setSelectedVehicles, insightDataFromApi, updateBuildForApi }, ref) => {

    const dispatch = useDispatch();
    let [dataId, setDataId] = useState("")
    //// console.log('vehicles ', vehicles.map( item => {// console.log(item.category) }) )
    // const [showMenu, setShowMenu] = useState(false)
    const [dummyUpdate, setDummyUpdate] = useState("")
    const [placeholder, setPlaceholder] = useState("Choose some vehicle")
    const selectedInsight = useSelector(state => state.selectedInsight)
    let [vehUpdateFromApi, setVehUpdateFromApi] = useState(false)

    // const selectedFeatures = useSelector(state => state.selectedFeatures)

    useEffect(() => {
        if(insightDataFromApi.hasData){
            //console.log('useEffect: ', [...insightDataFromApi.insight_selection.vehicles])
            setSelectedVehicles([...insightDataFromApi.insight_selection.vehicles])
            // insightDataFromApi.insight_selection.vehicles.forEach( veh =>{
            //     veh.values.forEach( catData =>{
            //         insightSelected(null, catData, veh.category, true)
            //         setDataId(Math.random())
            //     })
            // })
            
            // isFeatureDropdownSelected = true
            updateSelectedVehiclesData("", [...insightDataFromApi.insight_selection.vehicles], false)
            if(insightDataFromApi.insight_selection.vehicles.length > 0){
                setVehUpdateFromApi(true)
            }
            updateBuildForApi('vehicles')
        }
        const handler = () => {
            setShowMenu(false)
            setDummyUpdate(" ")
        };
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler)
        }
    },[insightDataFromApi])

    const toggleInsightDropdown = (e) => {
        e.stopPropagation();
        setDummyUpdate(" ")
        //// console.log(selectedFeatures.length)

        if (selectedFeatures.length > 0 || selectedInsightId == 17 || selectedInsightId == 18 || selectedInsightId == 20) {
            setShowMenu(!showMenu)
        } else {

        }

    }

    const handleMainInsightClick = (e) => {
        e.stopPropagation();
    }

    const insightSelected = (e, value, category, isChecked = false) => {
        console.log('vehicle selected: ', e, value, category, selectedVehicles)
        if( e != null){
            isChecked = e.target.checked
            e.stopPropagation();
        }
        
        if (isChecked) {
            let obj = selectedVehicles.find(item => item.category == category)
            if (obj) {
                if (value == "All") {
                    let tmp = vehicles.find(item => item.category == category)
                    let tmpList = selectedVehicles.map(item => {
                        if (item.category == category) {
                            item.values = [...tmp.values]
                        }
                        return item
                    })
                    updateSelectedVehiclesData(category, [...tmpList])
                } else {
                    let tmpList = selectedVehicles.map(item => {
                        if (item.category == category) {
                            item.values.push(value)
                        }
                        return item
                    })
                    updateSelectedVehiclesData(category, [...tmpList])
                }
            } else {
                if (value == "All") {
                    let tmp = vehicles.find(item => item.category == category)
                    updateSelectedVehiclesData(category, [...selectedVehicles, { ...tmp }])
                } else {
                    updateSelectedVehiclesData(category, [...selectedVehicles, { category: category, values: [value] }])
                }
            }
        } else {
            if (value == "All") {
                let tmpData = selectedVehicles.filter(item => item.category != category)
                updateSelectedVehiclesData(category, [...tmpData])
            } else {
                let tmpData = selectedVehicles.map(item => {
                    if (item.category == category) {
                        let values = item.values.filter(i => i != value);
                        //// console.log(values)
                        item.values = [...values]
                    }
                    return item
                })
                tmpData = tmpData.filter(e => e.values.length > 0)
                updateSelectedVehiclesData(category, [...tmpData])
            }
        }
    }

    const updateSelectedVehiclesData = (category, data, LetAPIcall = true) => {
        if (data.length) {
            setVehiclesDropdownSelected(true)
            updateBuildForApi('vehicles')
        } else {
            setVehiclesDropdownSelected(false)
        }
        setSelectedVehicles([...data])
        dispatch(updateSelectedVehicles([...data]))
        if (LetAPIcall) {
            onVehicleSelection(category, [...data])
        }
        getPlaceHolder([...data])
        homePageVehicles([...data])
        getUpdatedVehicles([...data])
    }

    const vehDropdownItems = ["Model Year", "Brand Type", "Brand", "Vehicle Type", "Segments", "Model", "Trim"]

    const getPlaceHolder = (data) => {
        console.log('getPlaceHolder: ', data)
        let text = "";
        vehDropdownItems.forEach(item => {
            data.forEach(d => {
                if (d.category == item) {
                    let apend = item == "Segments" ? "Segment" : item
                    if (d.values.length > 1) {
                        text = d.values.length + " " + apend + "s"
                    }
                    if (d.values.length == 1) {
                        text = d.values.length + " " + apend
                    }

                }
            })
        })
        if (text.length > 0) {
            setPlaceholder(text)
        } else {
            setPlaceholder("Choose some vehicle")
        }
    }

    const checkVehicleChecked = (category, value) => {

        if (value != "All") {
            let isSelected = false;
            selectedVehicles.map(item => {
                if (item.category == category) {
                    isSelected = item.values.includes(value)
                }
            })
            return isSelected
        } else {
            let isSelected = false;
            let tmpObj = selectedVehicles.find(item => item.category == category)
            if (tmpObj) {
                vehicles.map(item => {
                    if (item.category == category) {
                        if (item.values.length == tmpObj.values.length) {
                            isSelected = true
                        }
                    }
                })
            }
            return isSelected;
        }
    }

    const disabledList = [
        { insight: "Percentage of Models", values: ["Model", "Trim"] },
        { insight: "Percentage of trims", values: ["Trim"] },
        { insight: "Pricing Ladder", values: ["Trim"] },
        { insight: "Trim Share", values: ["Trim"] }
    ]

    const checkIsDisabled = (category) => {
        let isDisabled = false;
        disabledList.forEach(item => {
            if (selectedInsight == item.insight) {
                if (item.values.includes(category)) {
                    isDisabled = true;
                }
            }
        })
        return isDisabled
    }

    // Expose childFunction to parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
        updateSelectedVehiclesData
    }));

    return (
        <div className="dropdown" data-id={dataId}>
            <div className="d-grid gap-2">
                <button className={((isFeatureDropdownSelected || vehUpdateFromApi) ? '' : 'disabled-dropdown ') + "btn btn-lg text-dark txt-14px pr-10px btn-outline-secondary dropdown-toggle font-14p btn-w200p font-bold500"} onClick={e => toggleInsightDropdown(e)} type="button" disabled={(isFeatureDropdownSelected || vehUpdateFromApi) ? false : true} >
                    {placeholder}
                </button>
                {
                    showMenu && (
                        <ul className="dropdown-menu show border-0 cst-top-position">
                            {
                                vehicles.map(option => (
                                    <li key={option.category.split(" ").join("")} className={checkIsDisabled(option.category) ? "dropdown-item lable bg-light mt-1 normal-cursor" : "dropdown-item lable"} ><div onClick={e => handleMainInsightClick(e)}>{option.category} {checkIsDisabled(option.category) == false && <img src={arrowImg} alt="" className="float-end img-mt-7px " />}</div>
                                        {
                                            checkIsDisabled(option.category) == false && <div className="dropdown-submenu ">
                                                <ul className="dropdown-menu show border-0 vehicles-list" onClick={e => handleMainInsightClick(e)}>
                                                    <li className="dropdown-item " key={"all" + option.category.split(" ").join("")}>
                                                        <div className="form-check ">
                                                            <input className="form-check-input cursor" type="checkbox" onChange={e => insightSelected(e, "All", option.category)}
                                                                value="" id={"flexCheckChecked" + "all" + option.category.split(" ").join("")} checked={checkVehicleChecked(option.category, "All")} />
                                                            <label className="form-check-label w-100 cursor text-black" htmlFor={"flexCheckChecked" + "all" + option.category.split(" ").join("")} onClick={e => handleMainInsightClick(e)}>
                                                                All
                                                            </label>
                                                        </div>
                                                    </li>
                                                    {
                                                        option.values.map(value => (
                                                            <li className="dropdown-item " key={value}>
                                                                <div className="form-check ">
                                                                    <input className="form-check-input cursor" type="checkbox" onChange={e => insightSelected(e, value, option.category)}
                                                                        value="" id={"flexCheckChecked" + value.toString().split(" ").join("")} checked={checkVehicleChecked(option.category, value)} />
                                                                    <label className="form-check-label w-100 cursor text-black" htmlFor={"flexCheckChecked" + value.toString().split(" ").join("")} onClick={e => handleMainInsightClick(e)}>
                                                                        {value}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        }

                                    </li>
                                ))
                            }
                        </ul>
                    )
                }
            </div>
        </div>
    );
});

export default VehiclesDropdown;