import { useRef, useEffect, useState } from 'react'
import { getDeepdiveDataApi } from '../apis/deepdive'
import { responses } from '../utils/responses'
import '../assets/css/chart.css'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions, registerables  } from "chart.js";
import { Doughnut } from "react-chartjs-2";
// import { DownloadTableExcel } from "react-export-table-to-excel";
import { saveReportIssuesDataApi } from '../apis/reportIssues';
import DownloadTableExcelSheetJS from '../ExportInsight/HTMLTOExcel/DownloadTableExcelSheetJS';

import exportDeepDiveImg from "../assets/img/export_deep_dive.png"
import reportDeepDiveImg from "../assets/img/report_deep_dive.png"

ChartJS.register(ArcElement, Tooltip, Legend);
// Chart.defaults.plugins.legend


function DeepDive({ featureData, vehicleData, insight, cellValue, hideDeepdive, headersWithValues }) {

    // // console.log(featureData, vehicleData, insight, cellValue, headersWithValues)

    const [showPenrate, setShowPenrate] = useState(false)

    const [showReportPopup, setShowReportPopup] = useState(false)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);  // Initially, we are loading data
    const [featureHeader, setFeatureHeader] = useState("")
    const [subHeader, setSubHeader] = useState("")

    const [chartData, setChartData] = useState({});

    let chartOptions = {
        
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: true,
                formatter: (val, ctx) => {
                    return val + '%'
                },
            }
        }
    }

    let [isFitmentTrim, setIsFitmentTrim] = useState(false)


    useEffect(() => {

        const getAPiData = async () => {

            headersWithValues.forEach(e => {
                if (e.id == featureData.id) {
                    if (e.value == "Any") {
                        setFeatureHeader(e.header)
                    } else {
                        setFeatureHeader(e.value)
                    }
                }
            })

            let keys = Object.keys(vehicleData)
            keys = keys.filter(key => key != 'data')
            // // console.log(keys)
            if (keys.includes("Brand") || keys.includes("Model") || keys.includes("Trim")) {
                let txt = "";
                txt = vehicleData['Brand'] == undefined ? '' : vehicleData['Brand']
                txt = vehicleData['Model'] == undefined ? txt : txt + " " + vehicleData['Model']
                txt = vehicleData['Trim'] == undefined ? txt : txt + " " + vehicleData['Trim']
                setSubHeader(txt)
            } else {
                let txt = "";
                txt = vehicleData['Model Year'] == undefined ? '' : vehicleData['Model Year']
                txt = txt + " Vehicles"
                setSubHeader(txt)
            }
            setShowPenrate(false)
            let response = await getDeepdiveDataApi({ featureId: featureData.id, vehicles: [vehicleData], insight: insight })

            //// console.log( response.data)
            if (response.data.result == responses.RESULT_OK) {
                //// console.log(2)
                let filter = getAPiFilter(response.data.isTrim);
                if (filter.length > 0) {

                    let tmp = response.data.data.filter(e => filter.includes(e.value))
                    setData([...tmp])
                    setLoading(false);  // API call completed, set loading to false
                    // // console.log(82, '1', tmp)

                } else {
                    setData([...response.data.data])
                    setLoading(false);  // API call completed, set loading to false
                    //// console.log(86, '2')
                    if( insight == 'Fitment Type' && response.data.isTrim == true){
                        setIsFitmentTrim(true)
                    }else{
                        setIsFitmentTrim(false)
                    }
                }

                if (insight == "By Feature") {
                    processChartData(response.data.penData[0])
                }

                //// console.log( 89, Object.keys(response.data.data[0]), response.data )


            } else {
                //// console.log(1)
                setData([])
                setLoading(false);  // API call completed, set loading to false
            }
            setShowPenrate(true)
        }

        getAPiData()

    }, [])

    const processChartData = (data) => {
        // na_value, opt_not_value, opt_value, std_value
        setChartData({
            // labels: ["Standard", "Optional / fitted", "Optional / not fitted", "Not available"],
            datasets: [
                {
                    data: [formatPenrateValue(data.std_value), formatPenrateValue(data.opt_value), formatPenrateValue(data.opt_not_value), formatPenrateValue(data.na_value)],
                    backgroundColor: ['#0028CA', '#1050FF', '#FDD13A', '#828282'],
                    borderWidth: 0
                }
            ]
        })
    }

    const formatPenrateValue = (value) => {

        value = parseFloat(value)
        if (value > 1) {
            value = 1.0
        }
        let s = value > 0 ? (value.toFixed(2) * 100) : (value * 100)
        return parseInt(s.toString());
    }

    const getAPiFilter = (isTrim) => {
        let filter = [];
        if (insight == "Fitment Type" && isTrim == false) {
            if (cellValue == "Standard") {
                filter = ["std"]
            }
            if (cellValue == "Optional") {
                filter = ["opt"]
            }
        }
        if (insight == "Yes / No") {
            if (isTrim == false) {
                if (cellValue == "Y") {
                    filter = ["yes"]
                }
                if (cellValue == "N") {
                    filter = ["no"]
                }
            }
            if (isTrim == true) {
                if (cellValue == "Y") {
                    filter = ["std", "opt"]
                }
                if (cellValue == "N") {
                    filter = ["na"]
                }
            }

        }


        return [...filter];
    }

    const formatValue = (val) => {
        if (insight == "Average" || insight == "Minimum" || insight == "Maximum") {
            if( isNaN(val) ){
                return 'N/A'
            }else{
                if (val == '0') {
                    return ''
                }else{
                    return parseFloat(val).toFixed(2)
                }
            }
            // return parseFloat(val).toFixed(2)
        }
        if (insight == "Fitment Type") {
            return addButton(val)
        }
        if (insight == "Yes / No") {
            return addButton(val.toUpperCase());
        }
        if (insight == "Percentage of Models" || insight == "Percentage of trims") {
            if (["yes", "no"].includes(val)) {
                return addButton(val.toUpperCase())
            } else {
                return val + "%";
            }
        }
        if (insight == "By Feature") {
            return addButton(val)
        }

    }

    const addButton = (value) => {
        let className = "deepfive-btn-other";
        if (value == "na" || value == "NO") {
            className = "deepfive-btn-na"
        }
        if (value == "YES") {
            className = "deepfive-btn-green"
        }
        if (value == "std") {
            value = "Standard"
        }
        if (value == "opt") {
            value = "Optional"
        }
        if (value == "na") {
            value = "N/A"
        }
        return <button className={"btn  btn-sm " + className}>{value}</button>;
    }



    const getPricingTrim = () => {
        let opt = data.filter(d => d.value.includes("opt_"))
        let std = data.filter(d => d.value.includes("std_"))
        let na = data.filter(d => d.value.includes("na_"))
        if (loading) {
            return <h3><strong>Loading data...</strong></h3>;
        }
        if (na.length > 0 || data.length === 0) {
            return <h3><strong>Not available</strong></h3>
        }
        if (std.length > 0) {
            return <span><span  className='text-secondary'>Trim:</span> <br />{std[0].keyname.split("###")[0]}</span>
        }
        if (opt.length > 0) {
            return <span><span  className='text-secondary'>Trim:</span> <br />{opt[0].keyname.split("###")[0]}</span>
        }
    }

    const getPricingModel = () => {
        let opt = data.filter(d => d.value.includes("opt_"))
        let std = data.filter(d => d.value.includes("std_"))
        let na = data.filter(d => d.value.includes("na_"))
        if (std.length > 0) {
            return <div className='mb-3'><span className='text-secondary'>Model:</span> <br />{std[0].model}</div>
        }
        if (opt.length > 0) {
            return <div  className='mb-3'><span className='text-secondary'>Model:</span> <br />{opt[0].model}</div>
        }
        return ""
    }

    const getPricingVehPrice = () => {
        let opt = data.filter(d => d.value.includes("opt_"))
        let std = data.filter(d => d.value.includes("std_"))
        let na = data.filter(d => d.value.includes("na_"))
        if (data.length == na.length) {
            return ""
        }
        if (std.length > 0) {
            return "$" + std[0].value.split("_")[1]
        }
        if (opt.length > 0) {
            return "$" + opt[0].value.split("_")[1]
        }
    }

    const getPricingPackDetails = () => {
        let opt = data.filter(d => d.value.includes("opt_"))
        let std = data.filter(d => d.value.includes("std_"))
        let na = data.filter(d => d.value.includes("na_"))
        // console.log('getPricingPackDetails: ', data)
        if (opt.length > 0) {
            let packPrice = 0;
            let vehprice = parseInt(opt[0].value.split("_")[1]);
            let returnData = []
            returnData = opt.map(opt_data => {
                packPrice = packPrice + parseInt(opt_data.price.split("_")[0])
                vehprice = vehprice + parseInt(opt_data.price.split("_")[0])
                return <div className='row mt-2 mb-2 '>
                    <div className='row border-bottom mb-2 pb-4'>
                        <div className='col-4 text-primary'><span className='text-secondary'>Package</span> <br />{opt_data.pack_data.split("_#_")[0]}</div>
                        <div className='col-4 text-center text-secondary'>${opt_data.price.split("_")[0]}</div>
                        <div className='col-4 text-end'>${ vehprice }</div>
                    </div>
                    
                </div>
            }
            )
            returnData.push(<div className='row mt-2 mb-2 border-bottom pb-3'>
                    <div className='col-4'><strong>Total:</strong></div>
                    <div className='col-4 text-center text-secondary'>${packPrice}&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className='col-4 text-end pe-4'><strong>${vehprice}</strong>&nbsp;&nbsp;&nbsp;</div>
                </div>)
            return returnData
        }
        if (std.length > 0) {
            return <div className='row'>
                <div className='row mt-2 mb-2 border-bottom pb-3'>
                    <div className='col-6'><strong>Total:</strong></div>
                    <div className='col-6 text-end'><strong>${std[0].value.split("_")[1]}</strong></div>
                </div>
            </div>
        }
        if (na.length == 0) {
            return ""
        }
    }

    const deepDiveTableRef = useRef(null);
    const [showReportform, setShowReportform] = useState(false)
    const [reportSelectedValue, setReportSelectedValue] = useState('');
    const [reportTextValue, setReportTextValue] = useState('');

    const handleTextAreaChange = (event) => {
        setReportTextValue(event.target.value);
    };

    const reportOption = (type) => {
        setReportSelectedValue(type)
        setReportTextValue('')
        setShowReportform(true)
    }

    const hideReportForm = () => {
        setShowReportform(false)
        setReportTextValue('')
        setReportSelectedValue('')
    }

    const submitReport = () => {

        try {
            saveReportIssuesDataApi({ report_faq: reportSelectedValue, description: reportTextValue })
                .then((response) => {
                    if (response.data.result == responses.RESULT_OK) {
                        setShowReportform(false)
                        setReportTextValue('')
                        setReportSelectedValue('')
                    } else {
                        // console.log('false:', response.data.msg);
                    }
                })
                .catch((error) => {
                    // console.log('catch:', error.message);
                });
        } catch (error) {
            // console.log('catch:', error.message);
        }
    }

    return (
        <div className="container">
            <div className={(showReportPopup) ? 'hide-tab-div' : ''}>
                <div className="row mt-4 pb-3 border-bottom ps-3">
                    <div className="col-10">

                        {
                            (insight == "Average" || insight == "Minimum" || insight == "Maximum") &&
                            <div>
                                <h6 className='text-secondary' >
                                    {insight} {featureData.value}
                                </h6>
                                <h6>
                                    {formatValue(cellValue)}  - {subHeader}
                                </h6>
                            </div>
                        }


                        {
                            (insight == "Fitment Type" || insight == "Percentage of Models" || insight == "Percentage of trims" || insight == "Yes / No" || insight == "By Feature"
                                || ['Maximum MSRP', 'Minimum MSRP', 'Minimum Package Pricing'].includes(insight)
                            ) &&
                            <div>
                                <h6 className='text-secondary'>{insight} of {featureHeader} </h6>
                                <h6>{cellValue} - {subHeader}</h6>
                            </div>
                        }

                        <div className='text-secondary'>
                            Data Freshness
                        </div>
                        {
                            insight == "By Feature" &&
                            <div className=''>
                                VIN Sample Size	-	<strong>Coming soon</strong>
                            </div>
                        }


                    </div>
                    <div className="col-2 float-right">
                        <i className="fa-solid fa-xmark cursor" onClick={e => hideDeepdive(false)}></i>
                    </div>
                </div>
                {/* Export btn */}
                <div className="row ps-3 btn-row">
                    <div className="col-md-6 export-btn-col">
                        {/* <DownloadTableExcel
                            disabled
                            filename="VehiclePlannerPlus Deep dive"
                            sheet="Deep dive"
                            currentTableRef={deepDiveTableRef.current}
                        >
                            <center>
                                <img src={exportDeepDiveImg} className="adv-blue-img" alt="Advanced filters" />
                                <p className='deep-dive-nav-small'>Export</p>
                            </center>
                        </DownloadTableExcel> */}

                        <DownloadTableExcelSheetJS
                            disabled
                            filename="VehiclePlannerPlus Deep dive.xlsx"
                            sheet="Deep dive"
                            currentTableRef={deepDiveTableRef.current}
                        >
                            <center>
                                <img src={exportDeepDiveImg} className="adv-blue-img" alt="Advanced filters" />
                                <p className='deep-dive-nav-small'>Export</p>
                            </center>
                        </DownloadTableExcelSheetJS>
                    </div>
                    <div className="col-md-6 report-btn-col" onClick={e => setShowReportPopup(true)}>
                        <center>
                            <img src={reportDeepDiveImg} className="adv-blue-img" alt="Advanced filters" />
                            <p className='deep-dive-nav-small'>Report</p>
                        </center>
                    </div>
                </div>
                {
                    insight == "By Feature" && showPenrate &&
                    <div>
                        <div className='row ps-3 mt-4'>
                            <div className='mb-3'>
                                A breakdown of vehicles sold with {featureHeader}
                            </div>
                        </div>
                        <div className="row mt-2 ps-3">
                            <div className="col-md-7">

                                <div id="donutchart" className="donut-chart">
                                    <Doughnut data={chartData} options={{
            plugins: {legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    // // console.log()
                    const value = chartData.datasets[0].data[tooltipItem.dataIndex]
                    return value + '%'
                  },
                },
              }
            },
        }} />
                                </div>

                            </div>
                            <div className="col-md-5 mt-4">
                                <div className="legend-container">
                                    <div className="legend-div">
                                        <span className="legend-icon legend-standard"></span>
                                        <span className="legend-text">Standard</span>
                                    </div>
                                    <div className="legend-div">
                                        <span className="legend-icon legend-fitted"></span>
                                        <span className="legend-text">Optional/fitted</span>
                                    </div>
                                    <div className="legend-div">
                                        <span className="legend-icon legend-not-fitted"></span>
                                        <span className="legend-text">Optional/not fitted</span>
                                    </div>
                                    <div className="legend-div">
                                        <span className="legend-icon legend-not-appliable"></span>
                                        <span className="legend-text">Not available</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="row mt-2 ps-3">
                    <ul className="list-group list-group-flush">
                        {
                            data.map(r => (

                                ['Maximum MSRP', 'Minimum MSRP', 'Minimum Package Pricing'].includes(insight) == false &&
                                <li className="list-group-item ps-3" key={r.keyname} >
                                    <div className='row'>
                                        <div className='col-7'>{r.keyname}</div>
                                        <div className='col-3'>
                                            {
                                                ["Average", "Minimum", "Maximum"].includes(insight) &&
                                                <div> {addButton(r.fitment_type)} </div>
                                            }
                                        </div>
                                        <div className='col-2 pe-3'>
                                            {
                                                ["Average", "Minimum", "Maximum"].includes(insight) &&
                                                <span>{ r.value == 'N/A' ? '': formatValue(r.value) }</span>
                                            }
                                            {
                                                ["Average", "Minimum", "Maximum"].includes(insight) == false &&
                                                <span>{formatValue(r.value)}</span>
                                            }
                                        
                                        </div>

                                    </div>
                                </li>


                            ))
                        }
                    </ul>
                </div>

                {
                    (['Maximum MSRP', 'Minimum MSRP', 'Minimum Package Pricing'].includes(insight) || isFitmentTrim == true) &&
                    <div className='row mt-4 ps-3'>
                        <div className='row border-bottom'>
                            <div className='col-12'>{getPricingModel()}</div>
                            <div className='col-6'>{getPricingTrim()}</div>
                            <div className='col-6 text-end'><br />{getPricingVehPrice()}</div>
                        </div>
                        <div className=' mt-4 ps-3'>
                            {
                                getPricingPackDetails()
                            }
                        </div>
                    </div>
                }

                {/* To export deep dive */}
                <table style={{ display: 'none' }} ref={deepDiveTableRef}>
                    {
                        (insight == "Average" || insight == "Minimum" || insight == "Maximum") &&
                        <thead>
                            <tr>
                                <th>
                                    {insight} {featureData.value}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    {formatValue(cellValue)}  - {subHeader}
                                </th>
                            </tr>
                        </thead>
                    }

                    {

                        (insight == "Fitment Type" || insight == "Percentage of Models" || insight == "Percentage of trims" || insight == "Yes / No" || insight == "By Feature" ||
                            insight == "Maximum MSRP" || insight == "Minimum MSRP" || insight == "Minimum Package Pricing"
                        ) &&


                        <div>
                            <h6 className='text-secondary'>{insight} of {featureHeader} </h6>
                            <h6>{cellValue} - {subHeader}</h6>
                        </div>
                    }
                    <tbody>
                        {
                            data.map(r => (
                                <tr key={r.keyname}>
                                    <td>{r.keyname}</td>
                                    <td>{formatValue(r.value)}</td>
                                </tr>
                            ))
                        }
                    </tbody>

                </table>
            </div>
            <div className={(!showReportPopup) ? 'hide-tab-div' : ''}>
                <div className="row mt-4 pb-3 ps-3">
                    <div className="col-10">
                    </div>
                    <div className="col-2 float-right">
                        <i className="fa-solid fa-xmark cursor" onClick={e => setShowReportPopup(false)}></i>
                    </div>
                </div>
                <div className="row div-padding">
                    <img src={reportDeepDiveImg} className="deep-dive-img" />
                    <span className="deep-dive-nav">Thanks for Speaking up!</span>
                </div>

                <div className="row div-padding sub-heading-row">
                    <span className="heading-text">Tell us what you've found so we check things out for you.</span>
                </div>

                {(!showReportform) && (
                    <div className="table-responsive table-content">
                        <table className="table">
                            <thead></thead>
                            <tbody>
                                <tr className="main" onClick={e => reportOption(`I don't understand the data`)}>
                                    <td className="standard-td">
                                        I don't understand the data
                                    </td>
                                </tr>
                                <tr className="main" onClick={e => reportOption(`I don't think the data is accurate`)}>
                                    <td className="standard-td">
                                        I don't think the data is accurate
                                    </td>
                                </tr>
                                <tr className="main" onClick={e => reportOption(`I didn't get the data I was expecting`)}>
                                    <td className="standard-td">
                                        I didn't get the data I was expecting
                                    </td>
                                </tr>
                                <tr className="main" onClick={e => reportOption(`This deep dive doesn't answer my question`)}>
                                    <td className="standard-td">
                                        This deep dive doesn't answer my question
                                    </td>
                                </tr>
                                <tr className="main" onClick={e => reportOption(`None of these are relevant to my issue`)}>
                                    <td className="standard-td">
                                        None of these are relevant to my issue
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {(showReportform) && (
                    <div className="row message-main-div div-padding">
                        <div className="row" style={{ borderTop: "1px solid rgba(69, 90, 100, 0.16)" }}></div>
                        <div className="message-heading-padding">
                            <span className="faq-heading"> {reportSelectedValue} </span>
                        </div>
                        <div className="div-right-padding">
                            <textarea placeholder="Please elaborate as to what your specific issue was.." autofocus="autofocus" className="textarea-box" onChange={handleTextAreaChange}></textarea>
                        </div>
                    </div>
                )}

                <div>
                    <div className="row div-padding div-right-padding">
                        {reportTextValue.trim() != '' && (
                            <div className='submitBtnDiv'>
                                <button type="button" className="btn btn-outline-primary submit-btn-style" onClick={e => submitReport()}>Submit</button>
                            </div>
                        )}

                        {(showReportform) && (
                            <button type="button" className="btn btn-outline-primary cancel-btn-style" onClick={e => hideReportForm()}>Cancel</button>
                        )}
                        {(!showReportform) && (
                            <button type="button" className="btn btn-outline-primary cancel-btn-style" onClick={e => setShowReportPopup(false)}>Cancel</button>
                        )}

                    </div>
                </div>

            </div>

        </div>

    )
}

export default DeepDive;

